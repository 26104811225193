<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-header pt-3">
            <div class="card-title align-items-start flex-column">
              <h3 class="card-label font-weight-bolder text-dark">Maintenance triggers</h3>
              <span class="text-muted font-weight-bold font-size-sm mt-1">Update maintenance triggers of this company.</span>
            </div>
          </div>

          <form class="form position-relative">
            <!-- begin::Loading -->
            <div v-if="isMounted === false || isLoading || isLoadingForm" class="loading-container loading-overlap">
              <div class="loading-backdrop" />
              <div class="loading-block">
                <div class="blockui ml-auto mr-auto w-100">
                  <span class="float-left">Gathering data...</span>
                  <span class="spinner spinner-primary ml-auto" />
                </div>
              </div>
            </div>
            <!-- end::Loading -->

            <div class="card-body py-3">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="pb-1 w-500px" />
                      <th scope="col" class="font-weight-normal pb-1 w-200px">Notify when maintenance is due</th>
                      <th scope="col" class="font-weight-normal pb-1 w-200px">Notify before maintenance due</th>
                    </tr>
                  </thead>
                  <tbody>
                    <counter-table-row
                      v-for="counter in counters"
                      :key="`counterTableRow.${counter}`"
                      :ref="`counterTableRow.${counter}`"

                      :counter="counter"
                      :settings="(form.notification_settings && form.notification_settings.settings.maintenance.find((row) => row.counter === counter))"

                      :disabled="isLoading || isLoadingForm"
                    />
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card-body border-top">
              <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4 ml-auto d-block w-150px" :disabled="isLoading || isLoadingForm" @click.prevent="submitForm">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { validations, mutations as mutationsLib } from '@vedicium/core-vue';

import companyLib from '@vedicium/core-vue/build/modules/company';
import NotificationSettings from '@/libs/classes/notification_settings';

import counterTableRow from './components/counter.table-row.vue';

export default Vue.extend({
  components: {
    counterTableRow,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      counters: ['lamps', 'ft', 'st', 'stExt', 'stBench', 'bb', 'ledUva', 'ledRed', 'acrylicBench', 'acrylicAirco', 'rearCasing', 'acrylicCanopy', 'airco'],
      notification_settings: null,

      isLoadingForm: false,
      form: {
        notification_settings: null,
      },
    };
  },

  async mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Notifications', route: '/notifications' }, { title: 'Triggers', route: '/notifications/triggers' }, { title: 'Maintenance' }]);

    this.$set(this, 'isLoading', true);
    try {
      this.$set(this, 'notification_settings', await this.getNotificationSettings());
      this.$set(this.form, 'notification_settings', this.notification_settings.clone());
    } catch (e) {
      // Don't handle this error, because notification settings defaults will be handled
      console.error(e);
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async validate () {
      const validationArray = [];

      // Add validations per table row
      validationArray.push(...(this.counters.map((counter) => this.$refs[`counterTableRow.${counter}`].$v).filter((row) => !!row)));

      await validations.validateArray(validationArray);
    },
    async submitForm () {
      this.$errors.clear();
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        const mutations = mutationsLib.create(['settings.maintenance'], this.notification_settings, this.form.notification_settings);
        if (mutations.length) {
          const response = await this.$ws.patch(`${NotificationSettings.uri}/companies/${companyLib.current._meta.guid}`, { body: mutations });
          this.$set(this, 'notification_settings', new NotificationSettings().merge(response));
          this.$set(this.form, 'notification_settings', this.notification_settings.clone());
        }
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },

    async getNotificationSettings () {
      let notificationSettings = new NotificationSettings();
      try {
        notificationSettings = await NotificationSettings.getForCompany(companyLib.current._meta.guid);
      } catch (e) {
        console.error(e);

        // Set default settings
        notificationSettings = new NotificationSettings();
      }

      // Set defaults for maintenance
      this.counters.forEach((counter) => {
        const counterSetting = notificationSettings.settings.maintenance.find((row) => row.counter === counter);

        // Set default setting for counter
        if (!counterSetting) {
          notificationSettings.settings.maintenance.push({
            counter,
            maintenance_due: {
              enabled: false,
            },
            maintenance_preventative: {
              enabled: false,
              days_before_due: 14,
            },
          });
        }
      });

      return notificationSettings;
    },
  },
});
</script>
