<template>
  <tr>
    <template v-if="counter === null">
      <td colspan="42">Counter undefined</td>
    </template>

    <template v-else>
      <td class="align-middle">
        <h5 class="overflow-ellipsis">{{ $tu(`notifications.triggers.maintenance.counters.${counter}.title`) || `Counter '${counter}'` }}</h5>
        <span v-if="$te(`notifications.triggers.maintenance.counters.${counter}.description`)">{{ $t(`notifications.triggers.maintenance.counters.${counter}.description`) }}</span>
      </td>

      <td class="align-middle">
        <span :class="['switch', { 'switch-success': (settings && settings.maintenance_due.enabled === true) }]">
          <label>
            <input
              type="checkbox"
              :name="`switch.${counter}.maintenance_due.enabled`"
              :checked="(settings && settings.maintenance_due.enabled === true)"
              :disabled="disabled"
              @change="onPropertyChange('maintenance_due.enabled', $event.target.checked)"
            >
            <span />
          </label>
        </span>
      </td>

      <td class="align-middle d-flex">
        <span :class="['switch', { 'switch-success': (settings && settings.maintenance_preventative.enabled === true) }]">
          <label>
            <input
              type="checkbox"
              :name="`switch.${counter}.maintenance_preventative.enabled`"
              :checked="(settings && settings.maintenance_preventative.enabled === true)"
              :disabled="disabled"
              @change="onPropertyChange('maintenance_preventative.enabled', $event.target.checked)"
            >
            <span />
          </label>
        </span>

        <div class="input-group w-125px ml-5 ml-lg-auto">
          <select
            class="form-control"
            :value="(settings && settings.maintenance_preventative.days_before_due)"
            :disabled="disabled || (!settings || settings.maintenance_preventative.enabled !== true)"
            @change="onPropertyChange('maintenance_preventative.days_before_due', Number($event.target.value))"
          >
            <option
              v-for="day in 90"
              :key="`select.${counter}.maintenance_preventative.days_before_due.${day}`"
              :value="day"
            >
              {{ day }}
            </option>
          </select>
          <div class="input-group-append">
            <span class="input-group-text">
              Days
            </span>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import Vue from 'vue';
import objectPath from 'object-path';

export default Vue.extend({
  props: {
    counter: {
      type: String,
      default: null,
    },
    settings: {
      type: Object,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onPropertyChange (property = null, value = null) {
      objectPath.set(this.settings, property, value);
    },
  },
});
</script>
